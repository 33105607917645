* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  height: 100%;
  background-color: $color-grey-000;
}

main {
  display: block;
}

figure {
  margin: 0;
}

table {
  border-collapse: collapse;
}

img {
  vertical-align: middle;
  border-style: none;
}
