.NetworkRequired {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: white;

  @include from-breakpoint($tablet) {
    padding-top: 10rem;
    background-color: transparent;
  }

  &-content {
    background-color: $color-white;
    flex: 1 0 auto;
    max-width: 100%;
    padding: $sp-6;
    text-align: center;

    @include from-breakpoint($tablet) {
      flex: 0 0 40rem;
      border: $border-default;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);
      border-radius: 0.3rem;
    }

    a {
      font-weight: $bold;
      color: $color-blue-300;
    }
  }

  &-image {
    width: 12rem;
    height: 12rem;
  }

  &-message {
    margin: $sp-6 0;
  }
}
