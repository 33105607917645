$border-width: 0.1rem;
$border-color: $color-grey-200;
$border-style: solid;
$border-default: $border-width $border-color $border-style;
$border-color-dark: $color-grey-300;
$border-dark: $border-width $border-color-dark $border-style;
$border-thick: 0.3rem $border-color $border-style;
$border-radius-1: 0.2rem;
$border-radius-2: 0.4rem;
$box-shadow-default: 0 4px 4px rgba(0, 0, 0, 0.25);
