.DevelopmentSessions {
  @include breakpoint-container($desktop);

  padding: $sp-6 $sp-4;

  h3 {
    padding-bottom: $sp-4;
  }

  p {
    padding-bottom: $sp-6;
  }
}
