$mobile: 576px;
$tablet: 768px;
$desktop: 1024px;
$workspace-desktop: 1280px;
$wide-desktop: 1536px;

@mixin from-breakpoint($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}

@mixin breakpoint-container($max-width: $wide-desktop) {
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @include from-breakpoint($max-width) {
    max-width: $max-width;
  }
}

$two-column: $desktop;
$three-column: $wide-desktop;
