$sans-serif: 'Roboto', helvetica, verdana, sans-serif;
$font-size-default: 1.25rem;
$font-size-detail: 1.1rem;
$font-size-large: 1.6rem;
$normal: 400;
$medium: 500;
$bold: 700;

@mixin quickview-title {
  font-weight: $medium;
  color: $color-grey-900;
}

@mixin medication-storyline-title {
  @include t-h4;

  text-transform: uppercase;
  font-weight: $medium;
  letter-spacing: 0.15rem;
  font-size: $font-size-default;
}

@mixin t-details {
  font-size: $font-size-detail;
  color: $color-grey-800;
  font-weight: $normal;
}

@mixin t-details-dark {
  @include t-details;

  color: $color-black;
}

@mixin t-truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin t-hbase {
  margin-bottom: 0;
  font-weight: 500;
  line-height: 1.1;
}

@mixin t-h1 {
  @include t-hbase;

  font-size: 2.2rem;
}

@mixin t-h2 {
  @include t-hbase;

  font-size: 2rem;
}

@mixin t-h3 {
  @include t-hbase;

  font-size: 1.55rem;
  font-weight: $normal;
}

@mixin t-h4 {
  @include t-hbase;

  font-size: 1.4rem;
  line-height: 1.3;
  font-weight: $normal;
}

@mixin t-h5 {
  @include t-hbase;

  font-size: $font-size-default;
  font-weight: 500;
  margin-bottom: 0.2rem;
}

@mixin t-unit {
  color: $color-grey-800;
  font-size: 1rem;
  padding: 0 $sp-2 0 0.2rem;
  font-style: italic;
  font-weight: $normal;
}
