.StandaloneLanding {
  $landing-breakpoint: 450px;

  &-container {
    color: $color-white;
    background-color: $color-blue-400;
    height: 100%;
    position: relative;
    overflow-y: auto;

    @include from-breakpoint($landing-breakpoint) {
      color: $color-grey-800;
    }
  }

  &-banner {
    padding: $sp-4;
    text-align: center;
    margin: 0 !important;
  }

  &-body {
    display: flex;
    justify-content: center;
    padding: 8rem 0;
  }

  &-contentContainer {
    text-align: center;
    padding: $sp-6;

    @include from-breakpoint($landing-breakpoint) {
      background-color: $color-grey-100;
      width: 35rem;
    }
  }

  &-logo,
  &-whiteLogo {
    margin-bottom: $sp-6;
  }

  &-logo {
    height: 2.8rem;
    display: none;

    @include from-breakpoint($landing-breakpoint) {
      display: inline;
    }
  }

  &-whiteLogo {
    height: 2.8rem;

    @include from-breakpoint($landing-breakpoint) {
      display: none;
    }
  }

  &-innerContainer {
    padding: $sp-5;
    margin-bottom: $sp-6;

    @include from-breakpoint($landing-breakpoint) {
      background-color: $color-white;
    }

    p {
      padding-bottom: $sp-6;
    }
  }

  &-tenantLink:not(:last-of-type) {
    margin-bottom: $sp-5;
  }

  &-shareButton {
    margin-top: $sp-5;

    &.not-supported {
      display: none;
    }

    span {
      margin-left: $sp-2;
    }
  }

  &-shareIcon {
    width: 1.5rem;
    height: 1.5rem;
  }

  &-appBadges {
    display: flex;
    flex-direction: row;
    margin: 0 $sp-5;
    justify-content: space-between;

    @include from-breakpoint($landing-breakpoint) {
      margin: 0;
    }

    a {
      display: block;
    }
  }
}
