/*
  Apply this to a clickable element to expand its clickable area to a parent element.
  The clickable area will expand to the nearest relatively-positioned parent.
  To expand to a table row, apply `transform: rotate(0);` to the tr element.
 */
@mixin stretched-link($pointer: true) {
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $layer-1;
    pointer-events: auto;
    content: '';

    @if $pointer {
      cursor: pointer;
    }
  }
}

/* Hide an element to all devices except screen readers. */
@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

/* Target mobile Safari and iOS WebView */
@mixin ios-only {
  @supports (-webkit-overflow-scrolling: touch) {
    @content;
  }
}

@mixin ie-only {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

// TODO: Replace with proper grid autoplacement when off IE
@mixin autoplace-grid-container {
  overflow: hidden;
}

@mixin autoplace-grid($gap: 0) {
  display: flex;
  flex-wrap: wrap;
  margin: 0 #{-$gap} #{-$gap} 0;
}

@mixin autoplace-grid-item($cols: 2, $gap: 0) {
  border-width: 0 $gap $gap 0;
  border-style: solid;
  border-color: transparent;
  background-clip: padding-box;
  width: calc(100% / $cols);
}
