.MarketingPage {
  min-height: 100%;
  background-color: $color-blue-500;
  color: $color-white;

  &-content {
    padding: $sp-6;
    display: grid;
    gap: 2rem 5rem;
    grid-template:
      'header'
      'aside'
      'main'
      'footer'
      / 1fr;

    @include breakpoint-container;

    @include from-breakpoint($desktop) {
      padding: 5rem;
      grid-template:
        'header aside' min-content
        'main   aside' min-content
        'footer aside' 1fr
        / 1fr 2fr;
    }
  }

  p,
  h1,
  h2 {
    margin-top: 0;
    margin-bottom: 1em;
  }

  &-header {
    grid-area: header;

    h1 {
      margin: 1em 0;
    }
  }

  &-headerLogo {
    max-width: 100%;
  }

  &-main {
    grid-area: main;

    form {
      margin: 5rem 0;
    }
  }

  &-flash {
    display: flex;
    justify-content: center;
    align-items: center;

    > * {
      margin: 0 $sp-2;
    }

    img {
      width: 1em;
      height: 1em;
      flex: 0 0 auto;
    }
  }

  &-footer {
    grid-area: footer;
  }

  &-aside {
    align-self: center;
    grid-area: aside;
  }
}
