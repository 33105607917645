.LogoutMessage {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 20%;
  width: 50rem;
  margin-left: -25rem;
  margin-top: -2rem;

  &-image {
    height: 12rem;
    margin: 0 auto;
    display: block;
  }

  &-logo {
    width: 100%;
    margin-bottom: 4.5rem;
    text-align: center;
    height: 4rem;
    overflow-y: visible;
  }

  &-header {
    overflow-y: hidden;
  }

  &-text {
    color: $txt-primary;
    margin-top: 3rem;
  }
}
