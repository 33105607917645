.Styleguide {
  @include breakpoint-container($desktop);

  padding: $sp-6 $sp-5;

  &-color {
    display: inline-block;
    margin: 1rem;
  }

  &-colorBlock {
    display: inline-block;
    width: 10rem;
    height: 10rem;
  }

  .color-blue-000 {
    background-color: $color-blue-000;
  }

  .color-blue-300 {
    background-color: $color-blue-300;
  }

  .color-blue-400 {
    background-color: $color-blue-400;
  }

  .color-blue-500 {
    background-color: $color-blue-500;
  }

  .color-green-100 {
    background-color: $color-green-100;
  }

  .color-green-300 {
    background-color: $color-green-300;
  }

  .color-grey-000 {
    background-color: $color-grey-000;
  }

  .color-grey-100 {
    background-color: $color-grey-100;
  }

  .color-grey-200 {
    background-color: $color-grey-200;
  }

  .color-grey-300 {
    background-color: $color-grey-300;
  }

  .color-grey-500 {
    background-color: $color-grey-800;
  }

  .color-grey-600 {
    background-color: $color-grey-900;
  }

  .color-red-000 {
    background-color: $color-red-000;
  }

  .color-red-200 {
    background-color: $color-red-200;
  }

  .color-purple-100 {
    background-color: $color-purple-100;
  }

  .color-purple-200 {
    background-color: $color-purple-200;
  }

  .color-yellow-100 {
    background-color: $color-yellow-100;
  }

  .color-yellow-200 {
    background-color: $color-yellow-200;
  }

  .color-orange-000 {
    background-color: $color-orange-000;
  }

  .color-orange-200 {
    background-color: $color-orange-200;
  }

  .color-orange-300 {
    background-color: $color-orange-300;
  }

  .color-black {
    background-color: $color-black;
  }

  .color-white {
    background-color: $color-white;
  }
}
