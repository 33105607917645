@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

html {
  font-size: 62.5%;
}

body {
  font-family: $sans-serif;
  font-weight: $normal;
  color: $txt-primary;
  line-height: 1.3;
}

h1,
.h1 {
  @include t-h1;
}

h2,
.h2 {
  @include t-h2;
}

h3,
.h3 {
  @include t-h3;
}

h4,
.h4 {
  @include t-h4;
}

h5,
.h5 {
  @include t-h5;
}

h6,
.h6 {
  @include t-hbase;
}

::placeholder {
  font-style: italic;
}

.t-details {
  @include t-details;
}

.t-bold {
  font-weight: $bold;
}

.t-italic {
  font-style: italic;
}

.t-underline {
  border-bottom: $border-default;
}

.t-link {
  color: $link-color;
  cursor: pointer;
}

.t-empty {
  color: $color-grey-800;
}

body,
p,
a,
.form-control,
.btn,
.dropdown-menu,
.navbar-brand {
  font-size: 1.25rem;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: inherit;
}

a:hover {
  color: inherit;
  text-decoration: inherit;
}
